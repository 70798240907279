<script setup>
import { storeCollapseNav } from "@vueda/stores/storeCollapseNav.js";
import { storeDarkMode } from "@vueda/stores/storeDarkMode.js";
import { storeUser } from "@vueda/stores/storeUser.js";
import { useVersion } from "@vueda/use/useVersion.js";
import { breakpointsVueda } from "@vueda/utils/breakpoints.js";
import { getAppModelViewDotName } from "@vueda/utils/crudSupport.js";
import { fetchHelper } from "@vueda/utils/fetchSupport.js";
import { useBreakpoints } from "@vueuse/core";
import Divider from "primevue/divider";
import Popover from "primevue/popover";
import Tag from "primevue/tag";
import { computed, onMounted, ref, toRef, useTemplateRef } from "vue";
import { useRouter } from "vue-router";

import ButtonIcon from "@/components/ButtonIcon.vue";
import LinkIcon from "@/components/LinkIcon.vue";
import { LEGACY_HOSTNAME } from "@/utils/legacyHostname.js";

const userProfileRef = useTemplateRef("userProfile");
const version = useVersion();
const userStore = storeUser();
const loggedInUser = toRef(userStore, "loggedInUser");
const collapseNavStore = storeCollapseNav();
const darkModeStore = storeDarkMode();
const router = useRouter();

const newSiteApps = ["tim"]; // List of apps fully migrated to the new site
const newSiteModels = ["tim.timesheet"]; // List of models migrated to the new site
const collapsedAppLabel = ["Settings"]; // List of apps that should not show models

const navigationItems = ref([]);

// the old site has some really long tab names.
// to make the collapse animation better, we want to fit on one line
const labelsMap = {
    "Field compaction test results": "Field compactions",
    "Collection notifications": "Collections",
    "Received payments": "Payments",
    "Invoiceable projects": "Invoiceables",
    "Density result tests": "Density results",
    "Vacation breakpoints": "Vac. breakpoints",
    "Lab tests and dispatch": "Lab & dispatch",
};

const mapLabel = (label) => {
    return labelsMap[label] || label;
};

const upperOnlyTitleCase = (str) => {
    // don't botch 'HR' or 'A/R', but fix 'Lab tests and dispatch'
    return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

const isCollapsed = toRef(collapseNavStore, "isCollapsed");
const breakpoints = useBreakpoints(breakpointsVueda);
const greaterOrEqualLg = breakpoints.greaterOrEqual("lg");
const showLabels = computed(() => (greaterOrEqualLg.value ? !isCollapsed.value : true));
const showTooltip = computed(() => (greaterOrEqualLg.value ? isCollapsed.value : false));
const collapsedHamburger = computed(() => (!greaterOrEqualLg.value ? isCollapsed.value : false));
const activeBreakpoint = breakpoints.active();

// negation of computeds is annoying inline
const isExpanded = computed(() => !isCollapsed.value);

onMounted(async () => {
    collapseNavStore.init();
    const results = await fetchHelper(
        `${LEGACY_HOSTNAME}/api/navigation/`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        },
        "Failed to fetch navigation items.",
    );

    for (const appItem of results) {
        const isNewSiteApp = newSiteApps.includes(appItem.name);

        const appNavItem = {
            label: upperOnlyTitleCase(mapLabel(appItem.label)),
            app: appItem.name,
            isNewSite: isNewSiteApp,
            url: appItem.url,
            key: appItem.name || appItem.label,
            items: [],
        };

        if (appItem.label === "Settings") {
            appNavItem.verb = "settings";
        }

        if (appItem.models.length === 1) {
            delete appNavItem["items"];
            const model = appItem.models[0];
            appNavItem.model = model.name.split(".")[1]; // Extract model name
            appNavItem.view = "list"; // Default view
            appNavItem.url = model.url;
            appNavItem.key = getAppModelViewDotName(model);
        } else if (collapsedAppLabel.includes(appItem.label)) {
            if (!appItem.models?.length) {
                continue;
            }
            // too many sub items, just show the app as an item
            // appNavItem.url = appItem.items[0].url;
            delete appNavItem["items"];
        } else if (appItem.models && appItem.models.length > 0) {
            for (const modelItem of appItem.models) {
                const isNewSiteModel = newSiteModels.includes(modelItem.name);
                let calcApp;
                if (!modelItem.app) {
                    // this is an artifact of a top level item not
                    //  strictly associated with a particular app.
                    calcApp = modelItem.name.split(".")[0];
                } else {
                    calcApp = modelItem.app;
                }

                const navItem = {
                    label: mapLabel(modelItem.label),
                    app: calcApp,
                    model: modelItem.name.split(".")[1], // Extract model name
                    view: "list", // Default view
                    isNewSite: isNewSiteModel,
                    url: modelItem.url,
                    key: getAppModelViewDotName(modelItem),
                };
                if (isNewSiteModel) {
                    // stay on the new site
                    delete navItem["url"];
                }
                appNavItem.items.push(navItem);
            }
        }

        navigationItems.value.push(appNavItem);
    }
    // organize solo items into an app with a flag to omit the label
    const soloItems = navigationItems.value.filter((item) => !item.items);
    const soloApp = {
        label: null,
        key: "solo",
        items: soloItems,
    };
    navigationItems.value = navigationItems.value.filter((item) => item.items);
    navigationItems.value.unshift(soloApp);

    // setup disclosure status for categories
    for (const item of navigationItems.value) {
        if (item.items) {
            menuCategoryDisclosureStatus.value[item.key] = false;
        }
    }
});
// when the site is under lg, and we are using a top bar.
// I want to have categories collapsed by default.
// and add a button to the divider to toggle the category.
const menuCategoryDisclosureStatus = ref({});
const toggleDisclosure = (key) => {
    // accordion mode, there can only be one open at a time
    for (const [k, v] of Object.entries(menuCategoryDisclosureStatus.value)) {
        if (k === key) {
            menuCategoryDisclosureStatus.value[k] = !v;
        } else {
            menuCategoryDisclosureStatus.value[k] = false;
        }
    }
};
const navBackgroundClass = "bg-zinc-0 dark:bg-zinc-950";
const dev = import.meta.env.DEV;
const welcomeClick = () => {
    router.push({ name: "welcome" });
};
const welcomeUrl = computed(() => {
    return router.resolve({ name: "welcome" }).href;
});
</script>

<template>
    <header
        class="shrink-0 flex flex-col print:hidden z-40 transition-all ease-in-out"
        :class="{ 'lg:w-64': isExpanded, 'lg:w-20': isCollapsed }"
    >
        <div
            class="flex px-2 lg:hidden sticky border-christi-500 py-2 gap-2"
            :class="{
                [navBackgroundClass]: true,
                'border-b-4': isCollapsed,
            }"
        >
            <div>
                <button-icon
                    v-tooltip="isCollapsed ? 'Expand' : 'Collapse'"
                    class="grow whitespace-nowrap"
                    :verb="isCollapsed ? 'hamburgerClosed' : 'hamburgerOpen'"
                    size="small"
                    severity="success"
                    @click="collapseNavStore.toggle"
                />
            </div>
            <a class="block !no-underline grow" :href="welcomeUrl" @click="welcomeClick">
                <h1
                    class="text-center text-christi-500 grow overflow-hidden leading-loose tracking-widest whitespace-nowrap lg:hidden"
                >
                    <span class="text-sm">
                        <img
                            src="@/assets/shelby-text.svg"
                            alt="Shelby"
                            class="h-[0.7rem] inline align-baseline"
                        />&emsp13;<svg
                            class="h-[0.7rem] inline align-baseline text-black dark:text-white fill-current"
                            viewBox="0 0 180.332 17.55"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                aria-label="Engineering"
                                style="font-size: 23.8125px; line-height: 1.25; stroke-width: 0.264583"
                            >
                                <path
                                    d="M53.473 36.608H43.138v4.024h8.549v2.881h-8.549v4h10.335v2.882H40.066V33.75h13.407zM72.546 33.75v16.645h-2.238l-9.834-11.43v11.43h-3.072V33.75h2.238l9.835 11.406V33.75ZM85.048 41.084h7.691q.048.334.048.977 0 3.667-2.19 6.12-2.382 2.666-6.12 2.666-3.62 0-6.192-2.571-2.571-2.596-2.571-6.215 0-3.62 2.571-6.192 2.572-2.571 6.192-2.571 2.095 0 3.976.952 1.881.953 3.12 2.643l-2.524 1.762q-.81-1.071-2.025-1.69-1.19-.62-2.547-.62-2.358 0-4.025 1.691-1.667 1.667-1.667 4.025 0 2.357 1.667 4.048 1.667 1.667 4.025 1.667 1.952 0 3.214-1.048 1.286-1.072 1.762-2.739h-4.405zM99.288 50.395h-3.072V33.75h3.072zM119.052 33.75v16.645h-2.238l-9.835-11.43v11.43h-3.072V33.75h2.239l9.834 11.406V33.75ZM137.126 36.608H126.79v4.024h8.549v2.881h-8.549v4h10.335v2.882h-13.407V33.75h13.407zM154.747 36.608h-10.334v4.024h8.548v2.881h-8.548v4h10.334v2.882h-13.406V33.75h13.406zM170.011 44.68l4.286 5.715h-3.62l-3.833-5.144h-5.025v5.144h-3.071V33.75h8.739q2.405 0 4.12 1.69 1.714 1.691 1.714 4.073 0 1.69-.905 3.071-.905 1.382-2.405 2.096zm-2.62-2.381q1.191 0 2.025-.81.833-.81.833-1.976 0-1.167-.833-1.977-.834-.833-2.024-.833h-5.573v5.596zM180.393 50.395h-3.072V33.75h3.072zM200.158 33.75v16.645h-2.239l-9.834-11.43v11.43h-3.072V33.75h2.238l9.835 11.406V33.75ZM212.66 41.084h7.69q.048.334.048.977 0 3.667-2.19 6.12-2.382 2.666-6.12 2.666-3.62 0-6.192-2.571-2.571-2.596-2.571-6.215 0-3.62 2.571-6.192 2.572-2.571 6.192-2.571 2.095 0 3.976.952 1.882.953 3.12 2.643l-2.524 1.762q-.81-1.071-2.024-1.69-1.191-.62-2.548-.62-2.358 0-4.025 1.691-1.667 1.667-1.667 4.025 0 2.357 1.667 4.048 1.667 1.667 4.025 1.667 1.952 0 3.214-1.048 1.286-1.072 1.762-2.739h-4.405z"
                                    class="text"
                                    transform="translate(-40.066 -33.298)"
                                />
                            </g></svg
                        ><br class="block 2xs:hidden" />&emsp13;Management&emsp13;System
                    </span>
                </h1>
            </a>
            <div class="m-auto jetbrains-mono">
                {{ activeBreakpoint || "xs" }}
            </div>
        </div>
        <div
            class="flex flex-col lg:fixed lg:h-full w-[inherit] border-b-4 lg:border-b-0 lg:border-r-4 border-christi-500 py-2 transition-all ease-in-out"
            :class="{
                [navBackgroundClass]: true,
                'lg:w-64': isExpanded,
                'lg:w-20': isCollapsed,
                hidden: collapsedHamburger,
            }"
        >
            <a class="block !no-underline mx-2 mb-2" :href="welcomeUrl" @click="welcomeClick">
                <h1
                    v-tooltip.right="isCollapsed ? 'Shelby Engineering Management System' : undefined"
                    class="text-center text-christi-500 grow overflow-hidden leading-loose tracking-widest whitespace-nowrap hidden h-20 lg:flex lg:items-center lg:justify-center"
                >
                    <template v-if="isCollapsed">
                        <span class="small-caps text-sm"> SEMS </span>
                    </template>
                    <template v-else>
                        <span class="text-sm">
                            <!-- the space matters -->
                            <img
                                src="@/assets/shelby-text.svg"
                                alt="Shelby"
                                class="h-[0.68rem] inline align-baseline text-christi-500"
                            />&emsp13;<svg
                                class="h-[0.68rem] inline align-baseline text-black dark:text-white fill-current"
                                viewBox="0 0 180.332 17.55"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    aria-label="Engineering"
                                    style="font-size: 23.8125px; line-height: 1.25; stroke-width: 0.264583"
                                >
                                    <path
                                        d="M53.473 36.608H43.138v4.024h8.549v2.881h-8.549v4h10.335v2.882H40.066V33.75h13.407zM72.546 33.75v16.645h-2.238l-9.834-11.43v11.43h-3.072V33.75h2.238l9.835 11.406V33.75ZM85.048 41.084h7.691q.048.334.048.977 0 3.667-2.19 6.12-2.382 2.666-6.12 2.666-3.62 0-6.192-2.571-2.571-2.596-2.571-6.215 0-3.62 2.571-6.192 2.572-2.571 6.192-2.571 2.095 0 3.976.952 1.881.953 3.12 2.643l-2.524 1.762q-.81-1.071-2.025-1.69-1.19-.62-2.547-.62-2.358 0-4.025 1.691-1.667 1.667-1.667 4.025 0 2.357 1.667 4.048 1.667 1.667 4.025 1.667 1.952 0 3.214-1.048 1.286-1.072 1.762-2.739h-4.405zM99.288 50.395h-3.072V33.75h3.072zM119.052 33.75v16.645h-2.238l-9.835-11.43v11.43h-3.072V33.75h2.239l9.834 11.406V33.75ZM137.126 36.608H126.79v4.024h8.549v2.881h-8.549v4h10.335v2.882h-13.407V33.75h13.407zM154.747 36.608h-10.334v4.024h8.548v2.881h-8.548v4h10.334v2.882h-13.406V33.75h13.406zM170.011 44.68l4.286 5.715h-3.62l-3.833-5.144h-5.025v5.144h-3.071V33.75h8.739q2.405 0 4.12 1.69 1.714 1.691 1.714 4.073 0 1.69-.905 3.071-.905 1.382-2.405 2.096zm-2.62-2.381q1.191 0 2.025-.81.833-.81.833-1.976 0-1.167-.833-1.977-.834-.833-2.024-.833h-5.573v5.596zM180.393 50.395h-3.072V33.75h3.072zM200.158 33.75v16.645h-2.239l-9.834-11.43v11.43h-3.072V33.75h2.238l9.835 11.406V33.75ZM212.66 41.084h7.69q.048.334.048.977 0 3.667-2.19 6.12-2.382 2.666-6.12 2.666-3.62 0-6.192-2.571-2.571-2.596-2.571-6.215 0-3.62 2.571-6.192 2.572-2.571 6.192-2.571 2.095 0 3.976.952 1.882.953 3.12 2.643l-2.524 1.762q-.81-1.071-2.024-1.69-1.191-.62-2.548-.62-2.358 0-4.025 1.691-1.667 1.667-1.667 4.025 0 2.357 1.667 4.048 1.667 1.667 4.025 1.667 1.952 0 3.214-1.048 1.286-1.072 1.762-2.739h-4.405z"
                                        class="text"
                                        transform="translate(-40.066 -33.298)"
                                    />
                                </g></svg
                            ><br />
                            Management&emsp13;System
                        </span>
                    </template>
                </h1>
            </a>
            <div
                class="flex lg:flex-1 flex-row lg:flex-col flex-wrap lg:flex-nowrap max-w-full xs:max-lg:max-h-[80vh] overflow-y-auto relative py-2 gap-1 md:gap-2 2xl:gap-4"
                :class="{
                    'px-2': isExpanded,
                }"
            >
                <template v-for="(item, index) of navigationItems" :key="item.key">
                    <div
                        v-if="item.items?.length"
                        :class="{
                            'mb-4': index < navigationItems.length - 1,
                        }"
                        class="w-full flex lg:flex-col flex-wrap gap-1 2xl:gap-2"
                    >
                        <!-- header -->
                        <Divider
                            v-if="item.label"
                            class="!mt-0 tracking-wide"
                            align="left"
                            :pt="{ content: { class: '!bg-zinc-0 dark:!bg-zinc-950' } }"
                        >
                            <link-icon
                                v-if="!greaterOrEqualLg"
                                class="whitespace-nowrap no-underline"
                                :class="{ '!justify-start': isExpanded, 'm-auto': isCollapsed }"
                                :fluid="greaterOrEqualLg"
                                :verb="menuCategoryDisclosureStatus[item.key] ? 'collapseUp' : 'collapseDown'"
                                size="small"
                                :label="showLabels ? item.label : undefined"
                                severity="primary"
                                variant="outlined"
                                @click="toggleDisclosure(item.key)"
                            />
                            <template v-else>
                                {{ showLabels ? item.label : undefined }}
                            </template>
                        </Divider>
                        <!-- sub items -->
                        <template v-if="greaterOrEqualLg || menuCategoryDisclosureStatus[item.key] || !item.label">
                            <template v-for="innerItem of item.items" :key="innerItem.key">
                                <link-icon
                                    v-tooltip.right="showTooltip ? innerItem.label : undefined"
                                    :href="innerItem.isNewSite ? undefined : LEGACY_HOSTNAME + innerItem.url"
                                    class="whitespace-nowrap"
                                    :class="{ '!justify-start': isExpanded, 'm-auto': isCollapsed }"
                                    :fluid="greaterOrEqualLg"
                                    label-class="text-left font-normal"
                                    target="_blank"
                                    rel="noopener"
                                    :label="showLabels ? innerItem.label : undefined"
                                    size="small"
                                    label-align="left"
                                    :app="innerItem.app"
                                    :model="innerItem.model"
                                    :icon="innerItem.icon"
                                    :verb="innerItem.verb"
                                    :to="innerItem.to"
                                    :view="innerItem.view"
                                    v-bind="
                                        innerItem.isNewSite ?
                                            {
                                                onClick: innerItem.command,
                                            }
                                        :   undefined
                                    "
                                    severity="secondary"
                                    :skip-model-lookup="!innerItem.isNewSite"
                                    variant="outlined"
                                />
                            </template>
                        </template>
                    </div>
                </template>
            </div>
            <div v-if="dev" class="m-auto jetbrains-mono hidden lg:block">
                {{ activeBreakpoint || "xs" }}
            </div>
            <div class="hidden lg:flex mx-2 my-2">
                <button-icon
                    class="m-auto"
                    fluid
                    :verb="isCollapsed ? 'collapseRight' : 'collapseLeft'"
                    :label="isCollapsed ? undefined : 'Collapse'"
                    size="small"
                    severity="success"
                    @click="collapseNavStore.toggle"
                />
            </div>
            <div class="flex flex-row lg:flex-col mx-2 gap-2 mb-2 flex-wrap lg:flex-nowrap">
                <button-icon
                    :fluid="greaterOrEqualLg"
                    class="grow m-auto"
                    verb="showProfile"
                    :label="showLabels ? 'User Profile' : undefined"
                    size="small"
                    severity="secondary"
                    @click="userProfileRef.toggle($event)"
                />
                <button-icon
                    :fluid="greaterOrEqualLg"
                    class="grow m-auto"
                    verb="signOut"
                    :label="showLabels ? 'Sign Out' : undefined"
                    size="small"
                    severity="secondary"
                    @click="router.push({ name: 'sign-out' })"
                />
                <button-icon
                    :fluid="greaterOrEqualLg"
                    class="grow m-auto"
                    :verb="darkModeStore.isDark ? 'toggleDarkModeLight' : 'toggleDarkModeDark'"
                    :label="
                        showLabels ?
                            darkModeStore.isDark ?
                                'Light Mode'
                            :   'Dark Mode'
                        :   undefined
                    "
                    size="small"
                    severity="contrast"
                    @click="darkModeStore.toggle()"
                />
            </div>
            <popover ref="userProfile">
                <div class="flex flex-col gap-2 md:gap-3 2xl:gap-5 lg:max-w-[50vh]">
                    <div class="text-right">
                        <link-icon
                            verb="externalLink"
                            size="small"
                            label="Change Password"
                            :href="`${LEGACY_HOSTNAME}/admin/password_change/`"
                            target="_blank"
                            rel="noopener"
                            severity="secondary"
                        />
                    </div>
                    <div class="flex flex-row justify-between min-w-0">
                        <span>Full Name:</span><span>{{ loggedInUser?.formatted_name }}</span>
                    </div>
                    <div class="flex flex-row justify-between">
                        <span>User Name:</span><span class="disambiguation">{{ loggedInUser?.username }}</span>
                    </div>
                    <div class="flex flex-row justify-between">
                        <span class="min-w-min whitespace-nowrap">Group(s):</span
                        ><code v-if="loggedInUser?.is_superuser" class="text-maroon-500">superuser</code>
                        <div v-else class="flex flex-row justify-end flex-wrap gap-1 md:gap-2 2xl:gap-3">
                            <template v-for="group in loggedInUser?.groups" :key="group">
                                <Tag severity="success" size="small" rounded>{{ group }}</Tag>
                            </template>
                        </div>
                    </div>
                    <div class="flex flex-row justify-between">
                        <span>Client Version:</span><span class="tabular-numbers">{{ version.myVersion }}</span>
                    </div>
                    <div class="flex flex-row justify-between">
                        <span>Server Version:</span><span class="tabular-numbers">{{ version.serverVersion }}</span>
                    </div>
                </div>
            </popover>
        </div>
    </header>
</template>

<style scoped></style>
