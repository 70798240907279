<script setup>
import { useLinkModelView } from "@vueda/use/useLinkModelView.js";
import Button from "primevue/button";
import { usePassThrough } from "primevue/passthrough";
import { computed, useAttrs } from "vue";
import { useRouter } from "vue-router";

import FontAwesomeIconDisplay from "@/components/FontAwesomeIconDisplay.vue";
import { useIconMap } from "@/use/useIconMap.js";

const props = defineProps({
    icon: {
        type: [String, Array],
        default: undefined,
    },
    multiIcons: {
        type: Array,
        default: undefined,
    },
    verb: {
        type: String,
        default: undefined,
    },
    title: {
        type: String,
        default: undefined,
    },
    app: {
        type: String,
        default: undefined,
    },
    model: {
        type: String,
        default: undefined,
    },
    pk: {
        type: [String, Number, Array],
        default: undefined,
    },
    view: {
        type: [String, Array, Object],
        default: undefined,
    },
    to: {
        type: [String, Object],
        default: undefined,
    },
    onClick: {
        type: Function,
        default: undefined,
    },
    text: {
        type: Boolean,
        default: false,
    },
});
const router = useRouter();
const autoIcon = useIconMap(props);
const linkModelView = useLinkModelView(props);
const handleClick = (e) => {
    if (props.onClick) {
        e.preventDefault();
        props.onClick(e);
    } else if (props.to) {
        e.preventDefault();
        router.push(props.to);
    } else if (linkModelView.href.value && !linkModelView.actionDisabled.value) {
        e.preventDefault();
        linkModelView.navigate();
    }
    // let the click event propagate
};
const multiIconPt = computed(() =>
    props.multiIcons?.length ?
        {
            root: {
                class: "!w-auto",
            },
            icon: {
                class: "flex items-center justify-center",
            },
        }
    :   undefined,
);
const attrs = useAttrs();
const combinedPt = usePassThrough(
    multiIconPt,
    computed(() => attrs.pt || {}),
    {
        mergeSections: true,
        mergeProps: true,
    },
);
</script>

<template>
    <Button v-tooltip="title" v-bind="{ text }" :pt="combinedPt" @click="handleClick">
        <!-- primevue passes nothing via their button default slot... -->
        <!-- when using the default slot, they don't render the icon slot... -->
        <template #default>
            <slot />
        </template>
        <template v-if="autoIcon ?? multiIcons?.length ?? $slots.icon" #icon="slotProps">
            <slot name="icon" v-bind="{ autoIcon, multiIcons, ...slotProps }">
                <span :class="slotProps.class">
                    <font-awesome-icon-display :icon="autoIcon" :multi-icons="multiIcons" />
                </span>
            </slot>
        </template>
    </Button>
</template>
